<template>
<v-container class="p-5 text-center">
    <v-img src="@/assets/verify.svg">
    </v-img>
    <v-overlay :value="overlay" class="text-center" color="white" opacity="1">
      <v-progress-circular color="#00919B"
        size="64" indeterminate ></v-progress-circular>
      <p class="mt-10 black--text text-uppercase">{{ status }}</p>
    </v-overlay>
  </v-container>
</template>
<script>
import { Storage } from "@/helpers/Storage";
export default {
  data() {
    return {
      status: "Verfying",
      token: null,
      user_company_id: null,
      overlay: true,
      countDown : 0,
    };
  },
  created() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
    }
    if (this.$route.query.user_company_id) {
      this.user_company_id = this.$route.query.user_company_id;
    }

    if (this.token && this.user_company_id) {
      this.status = "recived data";
      this.verify();
    } else {
      this.countDown = 3
    }
  },
  watch: {
    countDown: {
      handler(value) {

          if (value > 0) {
              setTimeout(() => {
                  this.countDown--;

                  if(this.countDown == 2)
                  {
                    this.status = 'failed to read data. redirecting to home page'
                  }
                  if(this.countDown == 0)
                  {
                    this.$router.push({ name: "Home" });
                  }
              }, 1000);
          }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    verify() {
      this.status = "sending verification";
      if (this.axios.defaults.headers.common['Authorization'] === 'Bearer null') {
        this.axios.defaults.headers.common['Authorization'] = "Bearer " + this.token
      } 
      this.axios
        .get("/verify/" + this.user_company_id , {} , { headers : { Authorization: 'Bearer ' + this.token } } )
        .then( () => {
          this.status = "User Verified!";
          Storage.storeToken(this.token);
          this.axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.token;

          this.$router.push({ name: "Dashboard" });
        })
        .catch(({ error }) => {
          console.log(error);
            this.status = "User failed to verify";
            this.$user.Logout();
            this.$router.push({ name: "Home" });
        });
    },
  },
};
</script>